.c6ucwmu{top:43px;left:0;right:0;bottom:auto;border-radius:6px;border:1px solid rgba(0, 65, 143, 0.1);box-shadow:0 0 6px 0 rgba(0, 65, 143, 0.2);position:absolute;}
.ctiws5m{background-color:white;}
.i8oan7f{height:20px;padding:2px 8px;background-color:#edf1f8;border:none;font-size:14px;color:#262626;}
.l1jcr3r2{padding:12px 8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-flex-wrap:wrap;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:10px 8px;background-color:white;}
.t14pjzcb a{font-size:15px;font-weight:300;line-height:1;color:#0073ff;-webkit-text-decoration:underline;text-decoration:underline;}
.h1ilbptr{border-bottom:1px solid #e0e0e0;padding:12px 8px 12px 27px;position:relative;letter-spacing:0.5px;}.h1ilbptr a{color:#1e1e1e;}.h1ilbptr a :hover{font-weight:500;}.h1ilbptr a::before{content:'';background:url('/assets/icons/hot/fire.svg');position:absolute;width:11px;height:14px;left:8px;top:16px;}
.mxflbyr.l1jcr3r2 mark{color:#e03f19;background-color:inherit;}
.q1o7dgfy{background:white;border-bottom:1px solid #e0e0e0;width:100%;padding-bottom:12px;}.q1o7dgfy :last-child{border-bottom-width:0;padding-bottom:0;}.q1o7dgfy a{color:#1e1e1e;cursor:pointer;}.q1o7dgfy .title{-webkit-box-pack:start;-ms-flex-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:100%;margin-bottom:6px;font-size:15px;}.q1o7dgfy .title small{margin-left:8px;font-size:10px;-webkit-transform:scale(0.85) translate(-10%);-moz-transform:scale(0.85) translate(-10%);-ms-transform:scale(0.85) translate(-10%);transform:scale(0.85) translate(-10%);}.q1o7dgfy .title small:before{content:'/';font-size:10px;font-weight:normal;margin-right:4px;}.q1o7dgfy .summary{color:#909090;font-size:13px;font-weight:normal;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%;}
.nypz1xw{background:white;border-bottom:1px solid #e0e0e0;width:100%;padding-bottom:12px;}.nypz1xw :last-child{border-bottom-width:0;padding-bottom:0;}.nypz1xw a{font-size:15px;color:#1e1e1e;cursor:pointer;display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%;margin-bottom:6px;}.nypz1xw time{font-size:12px;color:#909090;}.nypz1xw time::before{content:'';background-image:url('/assets/icons/time/clock.svg');width:12px;height:12px;margin-right:6px;display:inline-block;vertical-align:middle;}
.rk0m78i.i8oan7f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:12px;}.rk0m78i.i8oan7f a{color:#515c69;}.rk0m78i.i8oan7f button{color:#909090;font-size:12px;cursor:pointer;}
.r1w6b3o2{padding:12px 8px;font-size:14px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;border-bottom:1px solid #e0e0e0;width:100%;}.r1w6b3o2 a{color:#1e1e1e;}.r1w6b3o2 img{cursor:pointer;}
